import React from 'react';
import {NavLink} from 'react-router-dom';

const Banner = () => {
    return (
      <div className="banner">
        <div className="banner-btn-container">
          <h1>Building Your Dream, One Project at a Time</h1>
          <button className="white-call-btn"><a className="call" href="tel:3309611095">Call Now</a></button>
        </div>
      </div>
    );
  }
  
  export default Banner;
  