import {React, useState} from 'react';
// import '../App.css';
import {Navbar, Banner, Flooring, Concrete, QuoteForm, SmallScreenMenu, Remodeling, Painting, Pricing, Footer, Facebookicon, Mission, ImageCarousel, TestCarousel} from './index';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import {
  NavLink
} from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
// import satisfied from '../Satisfied.svg';
import { AiOutlineForm, AiOutlineSchedule } from 'react-icons/ai';
import {MdMarkEmailUnread, MdWaterDrop, MdPayment, MdSentimentSatisfied} from 'react-icons/md';
import {BiHappy} from 'react-icons/bi';
// import Banner from './banner';
const App = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [concrete, setConcrete] = useState(false);
  const [house, setHouse] = useState(false);
  const [deck, setDeck] = useState(false);
  const [patio, setPatio] = useState(false);
  const [fence, setFence] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  
  return (
    <div className="App col">
      <BrowserRouter>
        <Navbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        <div id="location">
          <h2>Proudly serving Cleveland, Akron and surrounding areas.</h2>
        </div>
        { menuOpen ?
                  <>
                    <SmallScreenMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
                  </>
                  : null
                }
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Banner/>
                {/* <Mission/> */}
                <div id="choose-lvp">
                  <div id="choose-description">
                    <h2>Why choose us to install your LVP flooring?</h2>
                    <h3>At Pine Hill Contracting, we pride ourselves on offering a diverse range of options that larger companies simply can’t match. Why? Because we believe in tailoring every detail to match our customers’ vision. From meticulously crafted trim to seamless transitions, we’re committed to turning your flooring dreams into reality.</h3>
                  </div>
                </div>
                <div className="how-it-works hiw-root">
                <h2>What We Offer</h2>
                <div className=" main-steps works-steps">
                    <div className="works-step work-step-1">
                        <div className="blue-window">
                          <h3>Flooring Installation</h3>
                        </div>
                    </div>
                    <div className="works-step work-step-2">
                        <div className="blue-window">
                          <h3>Painting Projects</h3>
                        </div>
                    </div>
                    <div className="works-step work-step-3">
                          <div className="blue-window">
                          <h3>Kitchen Remodel</h3>
                          </div>
                    </div>
                    <div className="works-step work-step-4">
                        <div className="blue-window">
                          <h3>Bath Remodel</h3>
                        </div>
                    </div>
                </div>
              </div>
              {/* <TestCarousel className="test-carousel"/> */}
              {/* <div className="satisfaction">
                    <div>
                        <img className="satisfaction-jpg" src={"satisfied.png"} alt="Satisfaction Guaranteed"/>
                    </div>
                    <div className="satisfaction-p">
                        <h2>We provide a safe and effective cleaning service. You don't pay until you're happy with the job.</h2>
                    </div>
                </div> */}
                <ImageCarousel className="image-carousel"/>
                {/* <QuoteForm 
                  firstName={firstName} 
                  setFirstName={setFirstName} 
                  lastName={lastName} 
                  setLastName={setLastName} 
                  email={email} 
                  setEmail={setEmail} 
                  phone={phone}
                  setPhone={setPhone}
                  concrete={concrete}
                  setConcrete={setConcrete}
                  house={house}
                  setHouse={setHouse}
                  deck={deck}
                  setDeck={setDeck}
                  patio={patio}
                  setPatio={setPatio}
                  fence={fence}
                  setFence={setFence}
                  additionalInfo={additionalInfo}
                  setAdditionalInfo={setAdditionalInfo}
                /> */}
              </>
            }
          />
          <Route
            path="/flooring"
            element={
              <Flooring/>
            }
          />
          <Route
            path="/painting"
            element={
              <Painting/>
            }
          />
          <Route
            path="/pricing"
            element={
              <Pricing/>
            }
          />
           <Route
            path="/remodeling"
            element={
              <Remodeling/>
            }
          />
          <Route
            path="/getaquote"
            element={
              <QuoteForm
                  firstName={firstName} 
                  setFirstName={setFirstName} 
                  lastName={lastName} 
                  setLastName={setLastName} 
                  email={email} 
                  setEmail={setEmail} 
                  phone={phone}
                  setPhone={setPhone}
                  concrete={concrete}
                  setConcrete={setConcrete}
                  house={house}
                  setHouse={setHouse}
                  deck={deck}
                  setDeck={setDeck}
                  patio={patio}
                  setPatio={setPatio}
                  fence={fence}
                  setFence={setFence}
                  additionalInfo={additionalInfo}
                  setAdditionalInfo={setAdditionalInfo}
              />
            }
          />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
