import React from 'react';
import { AiOutlineForm, AiOutlineSchedule } from 'react-icons/ai';
import {MdMarkEmailUnread, MdWaterDrop} from 'react-icons/md';
import {BiHappy} from 'react-icons/bi';



const Painting = () => {
     return (
      <div className="flooring-container">
      <div id="floor-grid">
        <div className="flooring-top">
          <div id="painting-1">
            <div>
              <h2>Transform your walls with expert precision.</h2>
            </div>
          </div>
          <div id="flooring-2">
            <div>
              <h3>Instant Transformation</h3>
              <p>Stylish results for cozy rooms.</p>
            </div>
            <div>
              <h3>Increased Home Value</h3>
              <p>Boost property appeal and valuation.</p>
            </div>
            <div>
              <h3>Lasting Durability</h3>
              <p>Expert application ensures long-lasting protection!</p>
            </div>
          </div>
        </div>
        
        <div id="flooring-3">
          <h2>Our Expert Painting Services Include:</h2>
          <div>
            <h4>1. Surface Preparation:</h4>
            <p>- We meticulously prepare the surfaces, ensuring a clean and smooth canvas for the painting process.</p>
            <p>- Say goodbye to peeling paint, cracks, or uneven textures.</p>
          </div>
          <div>
            <h4>2. Precise Application:</h4>
            <p>- Our skilled painters handle every detail, from accurate measurements to flawless brush strokes.</p>
            <p>- We take pride in achieving even coverage, crisp edges, and impeccable results.</p>
          </div>
          <div>
            <h4>3. Quality Assurance:</h4>
            <p>- We stand behind our work. Expect long-lasting, beautiful walls that enhance your space.</p>
            <p>- Our commitment to excellence ensures your satisfaction.</p>
          </div>
        </div>
      </div>
        
      </div>
    );
  }
  
  export default Painting;
  