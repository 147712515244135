import React from 'react';

const Mission = () => {
    return (
      <div className="mission-container col">
        <h3>At Ohio Premium Pressure Washing our mission is to provide the highest quality exterior cleaning, while making the cleaning process as easy as possible for the customer.</h3>
      </div>

    );
  }
  export default Mission;
  