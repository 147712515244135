import React from 'react';

const Remodeling = () => {
  return (
    <div className="flooring-container">
      <div id="floor-grid">
        <div className="flooring-top">
          <div id="remodeling-1">
            <div>
              <h2>Transform Your Space with Expert Remodeling</h2>
            </div>
          </div>
          <div id="flooring-2">
            <div>
              <h3>Bathroom Remodeling</h3>
              <p>Upgrade your bathroom with modern fixtures, elegant tiles, and spa-like features.</p>
            </div>
            <div>
              <h3>Kitchen Remodeling</h3>
              <p>Revamp your kitchen with custom cabinets, granite countertops, and energy-efficient appliances.</p>
            </div>
          </div>
        </div>
        
        <div id="flooring-3">
          <h2>Our Remodeling Process:</h2>
          <div>
            <h4>1. Consultation and Design Planning</h4>
            <p>- Collaborate with our experts to plan your dream bathroom or kitchen.</p>
          </div>
          <div>
            <h4>2. Material Selection</h4>
            <p>- Choose from a wide range of high-quality materials for your remodeling project.</p>
          </div>
          <div>
            <h4>3. Skilled Installation</h4>
            <p>- Our experienced team ensures precise installation for lasting results.</p>
          </div>
          <div>
            <h4>4. Quality Assurance</h4>
            <p>- We stand behind our work to guarantee your satisfaction.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Remodeling;
