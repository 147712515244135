import React from 'react';
import {
    NavLink,
  } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
const Flooring = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1000px)' });
    const isLargerDisplay = useMediaQuery({ query: '(min-width: 1000px'})
    return (
      <div className="flooring-container">
      <div id="floor-grid">
        <div className="flooring-top">
          <div id="flooring-1">
            <div>
              <h2>Elevate your space with Luxury Vinyl Plank Flooring</h2>
            </div>
          </div>
          <div id="flooring-2">
            <div>
              <h3>Experience</h3>
              <p>With years of flooring expertise, we know what works best for different spaces.</p>
            </div>
            <div>
              <h3>Attention to Detail</h3>
              <p>We treat each project as if it were our own home, ensuring perfection.</p>
            </div>
            <div>
              <h3>Timely Completion</h3>
              <p>Minimize disruption—our efficient team completes replacements promptly.</p>
            </div>
          </div>
        </div>
        
        <div id="flooring-3">
          <h2>Our Floor Replacement Services Include:</h2>
          <div>
            <h4>1. Removal of Old Flooring:</h4>
            <p>- We carefully remove existing flooring, ensuring a clean slate for the replacement process.</p>
            <p>- Say goodbye to worn-out carpets, scratched hardwood, or faded tiles.</p>
          </div>
          <div>
            <h4>2. Material Selection:</h4>
            <p>- Consult with us to choose the perfect flooring material for your space.</p>
            <p></p>
          </div>
          <div>
            <h4>3. Precise Installation:</h4>
            <p>- Our experts handle every detail, from accurate measurements to flawless installation.</p>
            <p>- We take pride in aligning patterns, ensuring smooth transitions, and achieving impeccable results.</p>
          </div>
          <div>
            <h4>4. Customization:</h4>
            <p>- Want a personalized touch? From meticulously crafted trim to seamless transitions, we’re committed to turning your flooring dreams into reality.</p>
            <p>- Your vision becomes reality with our tailored floor replacement solutions.</p>
          </div>
          <div>
            <h4>5. Quality Assurance</h4>
            <p>- We stand behind our work. Expect long-lasting, beautiful floors that withstand daily life.</p>
            <p>- Our commitment to excellence ensures your satisfaction.</p>
          </div>
        </div>
      </div>
        
      </div>
    );
  }
  
  export default Flooring;