import { React } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { useMediaQuery } from 'react-responsive';
import {
  NavLink
} from 'react-router-dom';
const Navbar = (props) => {
  const {menuOpen, setMenuOpen} = props;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1000px)' })
  const isLargerDisplay = useMediaQuery({ query: '(min-width: 1001px)'})
  const handleOpen = () => {
    setMenuOpen(!menuOpen);
    console.log('handleOpen', menuOpen)
  }
    return (
      <>
        <div className="navbar">
          { isLargerDisplay &&
            <>
              <div className="logo-container"></div>
              <div className="navlinks">
                <NavLink to='/'>Home</NavLink>
                <NavLink to='/flooring'>Flooring</NavLink>
                <NavLink to='/painting'>Painting</NavLink>
                {/* <NavLink to='/getaquote'>Pricing</NavLink> */}
                <NavLink to='/remodeling'>Remodeling</NavLink>
              </div>
              <div className="col nav-btns">
                <button className="call-btn"><a className="call" href="tel:3309611095">Call Now</a></button>
                {/* <button className="call-btn"><a className="booking-link" href="https://book.housecallpro.com/book/Ohio-Premium-Pressure-Washing/5bb8114554bd40bd807700363c9711e2?attribution=Online%20Booking">Online Booking</a></button> */}
                {/* <NavLink className="quote-btn" to='/getaquote'>Online Quote</NavLink> */}
              </div>
            </>
          }
          
          { isTabletOrMobile &&
              <>
                <div className="col nav-btns">
                {/* <button className="call-btn"><a href="tel:2162599055">Call (216) 259-9055</a></button> */}
                {/* <button className="call-btn"><a className="booking-link" href="https://book.housecallpro.com/book/Ohio-Premium-Pressure-Washing/5bb8114554bd40bd807700363c9711e2?attribution=Online%20Booking">Online Booking</a></button> */}
                  <button className="call-btn"><a className="call" href="tel:2162599055">Call Now</a></button>
                </div>
                <div className="logo-container"></div>
                <AiOutlineMenu className="hamburger-icon" onClick={handleOpen}/>
              </>
          }
        </div>
      </>
    );
  }
  
  export default Navbar;
  