import React from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ImageCarousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
   
      return (
        <div className="image-slick-div">
          <h1>Gallery</h1>
          <Slider {...settings}>
            <div>
              <div className="bef-aft bef-aft1"></div>
            </div>
            <div>
              <div className="bef-aft bef-aft2"></div>
            </div>
            <div>
              <div className="bef-aft bef-aft3"></div>
            </div>
            <div>
              <div className="bef-aft bef-aft4"></div>
            </div>
            <div>
              <div className="bef-aft bef-aft5"></div>
            </div>
          </Slider>
        </div>
      );
  }
  export default ImageCarousel;