import React from 'react';
import { FaFacebook } from 'react-icons/fa';

const Footer = () => {
    return (
      <div className="footer row">
          <div className="footer-item">  
            <img  className="cert-logo" src="pinehilllogo.png" alt="Pine Hill Contracting Logo"></img>
          </div>
          <div className="face-copy">
            <p className="footer-item copyright">Copyright © 2024 | Pine Hill Contracting LLC</p>
            <a href="https://www.facebook.com/pinehillcontractingllc" target="_blank" rel="noopener noreferrer">
              <FaFacebook size={40} color="#3b5998" />
              
            </a>
          </div>
          <div className="footer-item"> 
            <p className="contact-container"><a href="tel:2162599055" className="contact-link">(330)961-1095</a><div className="divider">|</div><a className="contact-link email-foot" href = "mailto: pinehillcontracting@gmail.com">pinehillcontracting@gmail.com</a></p> 
          </div>  
      </div>
    );
  }
  
  export default Footer;
  